<template>
	<v-container fluid>
		<v-row align="center">
			<DateRangeSelector @selected="newDateRange" />
			<div class="pl-4 grey--text">{{dateRangeText}}</div>
		</v-row>

		<v-row v-if="$authorized([`manager`,`marketing`])">
			<v-col
				v-for="(metric, index) in metrics"
				xs="12"
				sm="6"
				md="4"
				xl="4"
				:key="index"
			>
				<KeyMetrics
					:title="metric.title"
					:sub-title="metric.subTitle"
					:goal="metric.goal"
					:value="metric.value"
					:dateRangeName="dateRangeName"
					:metricName="metric.name"
					@changed="getGoals"
					:forecast="metric.forecast"
					color="blue lighten-3"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import KeyMetrics from '@/components/material/KeyMetrics'
	import DateRangeSelector from '@/components/material/DateRangeSelector'

	export default {
		components: {
			KeyMetrics,
			DateRangeSelector
		},
		data () {
			return {
				dateRange: ``,
				dateRangeParams: ``,
				dateRangeName: ``,
				dateRangeText: ``,
				metrics: [
					{
						name: `totalEngagement`,
						title: `North Star Metric (NSM)`,
						subTitle: `Agrees + Posts + Comments + Shares`,
						value: null,
						goal: null,
						forecast: null,
						url: `/statistics/total-engagement/`,
					},
					{
						name: `totalAccounts`,
						title: `Members`,
						subTitle: ``,
						value: null,
						goal: null,
						forecast: null,
						url: `/v2/statistics/total-private-profiles/?include_newsletter_members=true`,
					},
					{
						name: `totalClimateDialogue`,
						title: `Partners`,
						subTitle: `(Date range filter not applied)`,
						value: null,
						goal: null,
						forecast: null,
						url: `/v2/statistics/total-climate-dialogue/`,
					},
					{
						name: `totalAnswers`,
						title: `Anwered Reviews`,
						subTitle: ``,
						value: null,
						goal: 0,
						forecast: null,
						url: `/v2/statistics/total-posts/?answered=true`,
					},
					{
						name: `totalActiveUsers`,
						title: `Active Users`,
						subTitle: `Only works with max 2 year span`,
						value: null,
						goal: null,
						forecast: null,
						url: `/v2/statistics/total-active-users/?metrics=users&e={"event_type":"_all"}&m=uniques&s=[{"prop":"platform","op":"is","values":["iOS","Android"]}]`,
					}
				],
			}
		},
		methods: {
			setTimeRange(timerange) {
				if (!timerange) return
				this.dateRangeText = `${this.dayjs(timerange.start).format('D MMM YYYY')} - ${this.dayjs(timerange.end).add(-1,'day').format('D MMM YYYY')}`
			},
			refetch_all_data () {
				this.metrics.forEach(o => {
					if (!o.url) return

					this.$httpInt.get(o.url, {
						params: {
							forecast: o.forecast,
							timerange: this.dateRange,
						}
					})
						.then(res => {
							if (o.name === `totalAccounts`) {
								const value = res.data.result.count
								this.$httpInt.get(`/v2/statistics/total-public-profiles/?forecast=${o.forecast}&timerange=${this.dateRange}`)
									.then(res => o.value = res.data.result.count + value)
							} else {
								o.value = res.data.count ?? res.data.result.count
								o.forecast = res.data.forecast
							}
						})
				})

				this.getGoals()
			},
			newDateRange(o) {
				this.reset()
				this.dateRange = o.date_range
				this.dateRangeParams = o.params
				this.dateRangeName = o.name
				this.refetch_all_data()
			},
			getGoals() {
				this.$httpInt.get(`/statistics/goals`)
					.then(res => this.metrics.forEach(o => o.goal = res.data[o.name] && res.data[o.name][this.dateRangeName] || null))
			},
			reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
		},
		name: `StatisticsKpi`,
	}

</script>
